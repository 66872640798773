const DOMAIN = 'api.calenso.io';
const BASE_URL = 'https://' + DOMAIN;

export const environment = {
  apiUrl: BASE_URL + '/api/v1/',
  baseUrl: BASE_URL,
  calioDashboardBaseUrl: 'https://dashboard.calenso.io',
  deployUrl: 'https://widget.calenso.io',
  encryptedKey: 'FtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ',
  eventImgBaseUrl: BASE_URL + '/webroot/files/events/image/',
  eventImgFallback: 'calenso-default-logo.svg',
  fallback_image: 'braincept_portofilio_calenso.png',
  footerLogoUrl: 'calenso_doubleblue.svg',
  logoBaseUrl: BASE_URL + '/webroot/files/partners/logo/',
  production: false,
  resourceImgUrl: BASE_URL + '/webroot/files/resources/image/',
  resourceTypeImgUrl: BASE_URL + '/webroot/files/resource_types/image/',
  stripeAPIKey: 'pk_test_qC6Q2zbwLgHgHEzXkHdKATbR',
  version: '7.0.1',
  workerAvatarUrl: BASE_URL + '/webroot/files/workers/avatar/',
};
